<template>
  <div>
    <slot>
      <div
        v-for="(i, j) in items"
        :key="i.id"
        class="pb-1 mb-1 border-bottom"
      >
        <component
          :is="initialData.find((k) => k.id == i.id) ? (existingComponent ?? component) : component"
          :name="`${name}[${j}]`"
          :values="i"
          v-bind="$attrs"
          @update="(v) => (items[j] = v)"
        >
          <b-button
            variant="outline-danger"
            @click="handleRemove(j)"
          >
            <i class="far fa-minus-circle"></i>
          </b-button>
        </component>
      </div>
    </slot>

    <slot name="add-button">
      <b-button @click="handleAdd">
        <span class="far fa-circle-plus"></span>
        {{ label }}
      </b-button>
    </slot>
  </div>
</template>

<script>
export default {
  name: 'MultipleItemsForm',
  props: {
    component: { type: String, required: true },
    existingComponent: { type: String, required: false, default: null },
    name: { type: String, required: true },
    initialData: { type: Array, required: true },
    defaultItem: { type: Object, required: true },
    addLabel: { type: String, required: false, default: null },
  },
  data() {
    return {
      label: this.addLabel ?? this.trans('general.addEntry'),
      items: this.initialData.map((i, j) => ({ ...i, id: i.id ?? `${new Date().valueOf()}${j}` })),
    };
  },
  methods: {
    async handleRemove(i) {
      this.items.splice(i, 1);
    },
    handleAdd() {
      this.items.push({
        ...JSON.parse(JSON.stringify(this.defaultItem)),
        id: new Date().valueOf(),
      });
    },
  },
};
</script>
